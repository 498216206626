import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
      return (

      <div>
            <p class="p13">
                At Akiiza Services, we specialize in providing exceptional landscaping and power/soft wash cleaning services to clients in North Carolina and the surrounding areas.
            </p>
              <p class="p13">
                With over 20 years of professional experience in the United States, Africa and abroad, we bring our expertise to every landscaping project undertaken as well as large scale pressure cleaning of offices and residential homes.
            </p>
              <p class="p13">
                Whether you need landscaping, garden work, office and residential cleaning services, our team of skilled professionals can help you create the outdoor and indoor atmosphere of your dreams.
                So, don't hesitate to contact us if you are ready to transform your outdoor/indoor space into a beautiful and clean area for you, your family or business!
            </p>
              <p class="p13">
                  You can reach us at 704-763-9303 or our email at akiiza@akiizaservices.com
            </p> 
      </div>
    );
  }
}

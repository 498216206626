import React, { Component } from 'react';

export class ExamplesOfOurWork extends Component {
    static displayName = "Name"; 
    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        return (
            <div>
                <h1 class="h12">Examples of Our Work</h1>

             </div>
        );
    }
}
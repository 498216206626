import React, { Component } from 'react';

export class Pricing extends Component {
    static displayName ="Pricing" //Counter.name;

    constructor(props) {
        super(props);
        this.state = { currentCount: 0 };
        this.incrementCounter = this.incrementCounter.bind(this);
    }

    incrementCounter() {
        this.setState({
            currentCount: this.state.currentCount + 1
        });
    }

    render() {
        return (
            <div>
                <h1 class="h12">Pricing</h1>

                <p class="p13">Get an instant quote by entering in the information below!</p>
                 
            </div>
        );
    }
}
import { ExamplesOfOurWork } from "./components/ExamplesOfOurWork";
import { Pricing } from "./components/Pricing";
import { Home } from "./components/Home";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
      path: '/pricing',
      element: <Pricing />
  },
  {
      path: '/examplesofourwork',
      element: <ExamplesOfOurWork />
  }
];

export default AppRoutes;

/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
      return ( 
          <header>
              <div className="d1">
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
            

                <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
               
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                      
            <ul className="navbar-nav flex-grow">
                <img src="logo-clear.png" width="820" height="60" />
                <NavItem >
                    <NavLink tag={Link} className="text-dark" to="/"><b className="h12">Home</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/Pricing"><b className="h12">Pricing</b></NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/ExamplesOfOurWork"><b className="h12">See Our Work!</b></NavLink>
                </NavItem>

                <a href="https://www.youtube.com/@TheMowingCrew" className="anchor1">
                    <img src="Youtube-Icon-Square.png" width="40" height="40" />
                </a>
                 
                <a href="https://www.facebook.com/profile.php?id=61561703841758"  className="anchor1">
                    <img src="logo-facebookpng-32214.png" width="40" height="40" />
                </a>
 
            </ul>
          </Collapse>
                  </Navbar>
              </div>

          
      </header>
    );
  }
}
